.arco-table-cell{
    text-align: center;
}
.tit{
    padding: 32px 0 16px 0;
    color: #222222;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
}
.con_left{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.first{
    width: 144px;
    height: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding-top: 30px; */
    margin-top: 42px;
}
.headimg{
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F4F4F4;
    border-radius: 50%;
    position: relative;
}
.headimg .head_img_box{
    width: 74px;
    height: 74px;
    overflow: hidden;
    border-radius: 50%;
}
.head_img_box img{
    width: 100%;
    height: 100%;
}
.user_name{
    height: 18px;
    line-height: 18px;
    color: #222222;
    font-family: Source Han Sans CN;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 16px;
}
.vip_type{
    width: 70px;
    height: 16px;
    position: absolute;
    bottom: 0;
    right: -30%;
}
.edit_user_name{
    margin-top: 14px;
    display: flex;
    height: 24px;
    line-height: 24px;
    align-items: center;
    justify-content: center;
    background: #F4F4F4;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
}
.edit_user_name span{
    margin-left: 5px;
}
.second{
    width: 100%;
    margin-top: 60px;
    border-bottom: 1px solid #F4F4F4;
    border-top: 1px solid #F4F4F4;
    padding-bottom: 15px;
}
.third{
    width: 100%;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
}
.count_box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.count_box img{
    width: 22px;
    height: 22px;
}
.count_box .img_box{
    width: 44px;
    height: 44px;
    background: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.count_box p{
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    margin: 10px 0;
}
.count_box .count{
    font-family: SF Pro Text;
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #222222;
}
.arco-pagination-item-active{
    background-color: #F4F4F4!important;
    color: #222222!important;
}
.pre_xzy_modal{

}
.pre_xzy_modal .arco-modal-header{
    height: 40px;
    font-size: 16px;
    line-height: 40px;
}
.pre_xzy_modal .arco-modal-title{
    color: #222222;
    font-weight: bold;
}
.pre_xzy_modal .arco-modal-close-icon{ 
    right: 15px!important;
    top: 15px!important;
}
.pre_xzy_modal .arco-modal-footer{
    border: none;
    padding: 0 16px;
}
.pre_xzy_modal .btns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pre_xzy_modal .butn{
    width: 106px;
    height: 32px;
    border-radius: 8px;
    line-height: 32px;
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}
.pre_xzy_modal .cancel{
    background: #666666;
}
.pre_xzy_modal .confirm{
    background: #222222;
}
.pre_xzy_modal .arco-modal-content{
    padding: 26px;
}
.pre_xzy_modal .arco-input-inner-wrapper{
    width: 212px;
    height: 33px;
    background-color: rgba(246, 246, 246, 1);
    padding:7px 10px;
    input{
      line-height: 1;
      &::placeholder{
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
.pre_xzy_modal .arco-input {
    width: 228px;
    height: 36px;
    line-height: 36px;
}


.user-center{
    overflow: hidden;
    padding-left: 60px;
    height: 100%;
    background-image: url('../../assets/v2/imgs/userbg.png');
    // background-size: 90% 90%;
    // background-position: center center;
    background-repeat: no-repeat;
    .big-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 9px;
        img{
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }
        p{
            font-weight: 600;
            font-size: 36px;
            color: #31393E;
            line-height: 50px;
            letter-spacing: 1px;
        }
    }  
}

.uc-tit{
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    color: #31393E;
    line-height: 28px;
    position: relative;
    margin: 44px 0 26px 0;
    &::before{
        content: '';
        width: 4px;
        height: 24px;
        background: #02C1A3;
        position: absolute;
        top: 2px;
        left: -14px;
    }
}
.basic{
    margin-left: 34px;
    display: flex;
    align-items:center;
    .img{
        width: 66px;
        height: 66px;
        border-radius: 50%;
        margin-right: 29px;
        .arco-avatar{
            width: 100%;
            height: 100%;
        }
    }
    .bas-info{
        .user-name{
            font-weight: bold;
            font-size: 24px;
            color: #252C3E;
            line-height: 33px;
            letter-spacing: 2px;
            position: relative;
            display: flex;
            align-items: flex-end;
        }
        .user-phone{
            font-weight: 400;
            font-size: 14px;
            color: #252C3E;
            line-height: 20px;
            letter-spacing: 1px;
            margin: 10px 0;
        }
        .user-wx{
            font-weight: 400;
            font-size: 14px;
            color: #252C3E;
            line-height: 20px;
            letter-spacing: 1px;
        }
        .edit{
            margin-left: 10px;
            margin-bottom: 4px;
            cursor:pointer;
            width: 13px;
            height: 12px;
            display: inline-block;
            // position: absolute;
            // bottom: 0;
            // right: 9px;
            // background-image: url('../../assets/v2/imgs/closed.png');
        }
        .unbind{
            width: 34px;
            height: 19px;
            display: inline-block;
            background: #F1F1F1;
            border-radius: 4px;
            border: 1px solid #CBCBCB;
            font-weight: 400;
            font-size: 10px;
            color: #797979;
            line-height: 19px;
            text-align: center;
            margin-left: 14px;
            cursor:pointer;
        }
    }
}
.vip-info{
    // margin-left: 34px;
    .v-info{
        margin-left: 129px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        .c-label{
            color: #999999;
        }
        .c-value{
            color: #31393E;
            font-weight: bold;
        }
        .c-upbtn{
            display: inline-block;
            width: 34px;
            height: 19px;
            background: linear-gradient( 270deg, #E97C45 0%, #FC6332 100%);
            border-radius: 4px;
            font-weight: bold;
            font-size: 10px;
            color: #FFEDC9;
            line-height: 19px;
            text-align: center;
            margin-left: 14px;
            cursor: pointer;
        }
    }
    .vip-level{
        margin-top: 0;
    }
}

.pay-record{
    padding-left: 129px;
    table{
        th,td{
            min-width: 176px;
            height: 34px;
            text-align: left;
            padding:0 25px;
            border: 1px solid #EFEFEF;
        }
        th{
            background: #02C1A3;
            color: #ffffff;
        }
    }
    tbody{
        tr{
            &:nth-of-type(even){
                background: #F3FFFD;
            }
        }
    }
    .z-pagination{
        width: 704px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        div{
            width: 32px;
            height: 26px;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px solid #E3E3E3;
            font-weight: 400;
            font-size: 10px;
            color: #676767;
            line-height: 26px;
            text-align: center;
            margin-right: 7px;
            cursor: pointer;
        }
        .prev-page,.next-page{
            width: 58px;
            height: 26px;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px solid #E3E3E3;
        }
        .cur-page{
            background: #02C1A3;
            border: 1px solid #02C1A3;
            color: #FFFFFF;
        }
    }
}

.pre_xzy_modal{
    padding: 0 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .titss{
        // display: none;
        width: 100%;
        height: 28px;
        font-weight: 600;
        font-size: 20px;
        color: #31393E;
        line-height: 28px;
        margin: 43px 0 37px 0;
        text-align: center;
    }
    .name-input{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .ni-label{
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
        margin-right: 11px;
    }
    .arco-input-inner-wrapper{
        flex: 1;
        height: 33px;
    }
    .arco-modal-content{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .surebtnn{
        margin-top: 53px;
        width: 100%;
        height: 44px;
        background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
        border-radius: 5px;
        line-height: 44px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 44px;
        letter-spacing: 2px;
    }
    .wx-qrcode{
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.first-tit{
    margin-top: 9px;
}

.pre_xzy_modal{
    .input-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .label_pb{
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
          margin-right: 11px;
        }
        .arco-input-inner-wrapper{
          width: 212px;
          height: 33px;
          background-color: rgba(246, 246, 246, 1);
          padding:7px 10px;
          input{
            line-height: 1;
            &::placeholder{
              font-size: 12px;
              color: rgba(153, 153, 153, 1);
            }
          }
        }
    }
    .surebtnn{
        margin-top: 0;
    }
}


.custom-table{
    padding: 0 129px;
    /* 修改Table的特定样式 */
    border-collapse: collapse;
    /* 修改Table中的单元格样式 */
    table{
        th,td{
            min-width: 176px;
            height: 34px;
            text-align: left;
            padding:0 25px;
            border: 1px solid #EFEFEF;
        }
        th{
            background: #02C1A3;
            color: #ffffff;
        }
    }
    /* 修改Table的行样式 */
    tbody{
        tr{
            &:nth-of-type(even){
                background: #F3FFFD;
            }
        }
    }
    /* 修改Table的头部样式 */
    thead {
        th{
            background: #02C1A3;
            color: #ffffff;
        }
    }
    .arco-table-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .arco-pagination-item-active{
        background: #02C1A3!important;
        color: #ffffff!important;
    }
    .arco-pagination-item{
        width: 32px;
        height: 26px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #E3E3E3;
        font-weight: 400;
        font-size: 10px;
        color: #676767;
        line-height: 26px;
        text-align: center;
        margin-right: 7px;
        cursor: pointer;
    }
    // arco-pagination-item-prev arco-pagination-item-disabled
    .arco-pagination-item-prev {
        width: 58px;
        height: 26px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #E3E3E3;
    }
    .arco-pagination-item-next {
        width: 58px;
        height: 26px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #E3E3E3;
    }
    .arco-pagination-item-disabled{
        cursor: not-allowed;
    }
}


