.aside{
  width: 260px;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  /* background-color: rgba(255,255,255,0.4); */
  /* overflow-y: scroll; */
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
  .top-router{
    display: flex;
    padding: 20px;
    .tr-item{
      width: 113px;
      height: 100px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:hover{
        box-shadow: 0 16px 24px -16px rgb(0 0 0 / 50%);
        transform: scale(1.05);
        z-index: 10;
    }
    }
    img{
      width: 48px;
      height: 47px;
    }
    p{
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
    .more-ai{
      background: linear-gradient(#D5FFEB 0%, #ACF9D4 100%);
      color: #02C1A3;
      margin-right: 10px;
    }
    .pk{
      background: linear-gradient( #FFF0E7 0%, #FFE5DA 100%);
      color: #FC6332;
    }
  }
  .midcontent{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .chat-history{
    overflow-x: scroll;
    .chat-item{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 72px;
      padding: 0 12px;
      border-top: 1px solid #F4F4F4;
      &:last-child{
        border-bottom: 1px solid #F4F4F4;
      }
      .c-logo{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 8px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .info{
        flex: 1;
        display: flex;
        flex-direction: column;
        .name-date{
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            display: flex;
          .name{
            width: 131px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .date{
            flex: 1;
            text-align: right;
            color: #333333;
            white-space: nowrap;
          }
        }
        .cont{
          width: 193px;
          margin-top: 6px;
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .func{
    flex: 1;
    border-top: 1px solid #F4F4F4;
    .fun-item{
      height: 52px;
      border-bottom: 1px solid #F4F4F4;
      display: flex;
      align-items: center;
      padding: 0 18px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover{
          box-shadow: 0 16px 24px -16px rgb(0 0 0 / 50%);
          transform: scale(1.05);
          z-index: 10;
      }
      img{
        width: 20px;
        height: 20px;
        margin-right: 11px;
      }
      p{
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
    }
  }
  .about-us{
    width: 100%;
    text-align: center;
    align-self: flex-end;
    padding: 26px 0;
    div{
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-bottom: 6px;
    }
    p{
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 20px;
    }
  }
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  display: none;
}

.short{
  width: 90px;
  overflow: hidden;
  .func{
    .fun-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 85px;
      padding: 0;
      border-bottom: 1px solid #F4F4F4;
      img{
        width: 20px;
        height: 20px;
        margin-right:0;
      }
      p{
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        margin-top: 9px;
      }
    }
  }
}