
.top-bar-fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
  }
  .top-bar{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo{
    box-sizing: content-box;
    width: 169px;
    height: 37px;
    padding-left: 46px;
    padding-right: 45px;
    // background: linear-gradient( 270deg, rgba(5,226,208,0) 0%, rgba(2,193,163,0.14) 100%);
  }
  .short_logo{
    box-sizing: content-box;
    width: 57px;
    height: 37px;
    padding-left: 17px;
    padding-right: 16px;
    // background: linear-gradient( 270deg, rgba(5,226,208,0) 0%, rgba(2,193,163,0.14) 100%);
  }
  .login-register{
    width: 136px;
    padding: 15px;
    height: 100%;
    .real_head{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
    }
    .setting_out_box{
      display: none;
      position: absolute;
      bottom: -20px;
      right: 50%;
      transform: translate(50%,50%);
      cursor: pointer;
      .zanwei{
        height: 30px;
      }
    }
    .so_box{
      width: 30px;
      height: 30px;
      position: relative;
      &:hover .setting_out_box{
        display: block;
      }
    }

    .so_border{
      width: 45px;
      height: 1px;
      background: #EAEAEA;
    }
    .setting_out{
      width: 56px;
      height: 64px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
      opacity: 0.95;
      padding: 0 6px;
      font-weight: 400;
      font-size: 12px;
      color: #31393E;
      .txt{
        display: block;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #31393E;
        line-height: 30px;
      }
    }
  }
  .head-box{
    width: 100%;
    height: 100%;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    background: #02C1A3;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }
  .head-img{
    width: 22px;
    height: 22px;
  }
  .head-box span{
    margin-left: 7px;
  }
  .icon-collapse{
    margin-left: 10px;
    cursor: pointer;
  }
  .coll-logo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .top-bar-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.08);
    z-index: 1;
  }

