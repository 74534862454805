

.txt1 {
    font-family: Source Han Sans CN;
    font-size: 18px;
    font-weight: bolder;
    line-height: 18px;
    margin-top: 20px;
}
.arco-checkbox-text{
    color:rgba(179, 179, 179, 1);
}
.color-txt{
    color: rgba(9, 125, 255, 1);
}

.select_btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    color: #148a81;
    background-color: #e3f8f5;
    border: 1px solid #148a81;
    position: fixed;
    bottom: 0;
    right: 0;
    cursor: pointer;
}
.selectmodel{
    display: flex;
}
.select_model{
    .model_tit{
        margin-bottom: 16px;
        .model_tit_txt{
            font-weight: 600;
            font-size: 20px;
            color: #31393E;
            line-height: 28px;
        }
    }
    .model_list_box{
        display: flex;
        .model_list_cont{
            width: 225px;
            height: 300px;
            padding: 9px 9px 12px 0;
            border: 1px solid #F4F4F4;
            display: flex;
            flex-direction: column;
            .search_model{
                position: relative;
                .arco-input-inner-wrapper{
                    width: 204px;
                    height: 26px;
                    background: #F1F1F1;
                    border-radius: 13px;
                    background: #F1F1F1;
                }
                input{
                    width: 100%;
                    height: 100%;
                    padding-left: 25px;
                    &::placeholder{
                        font-weight: 400;
                        font-size: 12px;
                        color: #C9C9C9;
                    }
                }
                .search_icon{
                    width: 13px;
                    height: 13px;
                    position: absolute;
                    top: 7px;
                    left: 9px;
                }
            }
            .all_count{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #333333;
                line-height: 17px;
                margin-top: 10px;
            }
            .model_list{
                flex: 1;
                overflow-y: scroll;
                padding-top: 9px;
                padding-left: 7px;
                .arco-checkbox-mask{
                    width: 12px;
                    height: 12px;
                }
                .arco-checkbox-text{
                    font-weight: 400;
                    font-size: 12px;
                    color: #333333;
                    line-height: 17px;
                }
                .arco-checkbox{
                    line-height: 27px;
                }
            }
        }
        .model_check{
            width: 225px;
            height: 300px;
            border: 1px solid #F4F4F4;
            padding: 15px 18px;
            border-left: none;
            display: flex;
            flex-direction: column;
            .count{
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
                margin-bottom: 3px;
                span{
                    color: #333333;
                }
                .clear_all{
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
            .model_check_list{
                flex:1;
                overflow-y: scroll;
            }
            .model_check_txt{
                font-weight: 400;
                font-size: 12px;
                color: #333333;
                margin-top: 10px;
                line-height: 17px;
            }
        }
    }
}
.btn_box_sel{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .cancel_btn{
        width: 100px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 5px;
        border:1px solid #02C1A3;
        font-weight: 500;
        font-size: 13px;
        color: #02C1A3;
        cursor: pointer;
    }
    .sure_btn{
        width: 100px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
        border-radius: 5px;
        font-weight: 500;
        font-size: 13px;
        color: #ffffff;
        margin-left: 14px;
        cursor: pointer;
    }
}

