.login-info{
  flex: 1;
  height: 179px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right{
  height: 100%;
  width: 100%;
  padding: 0 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login-img {
  width: 50px;
  height: 50px;
}

.txt1 {
  font-size: 18px;
  font-weight: bolder;
  line-height: 18px;
  margin-top: 20px;
}
.login-btn{
  width: 266px;
  height: 44px;
  background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  letter-spacing: 2px;
  text-align: center;
}
.arco-checkbox-text{
  color:rgba(179, 179, 179, 1);
}
.color-txt{
  color: rgba(9, 125, 255, 1);
}
.huoqu{
  padding: 10px;
}
.newcenter{
  top: 50%;
  transform: translateY(-50%);
}
.arco-input-group-addafter{
  border: none;
}
.arco-btn-outline:not(.arco-btn-disabled){
  border: none;
  color: rgba(34, 34, 34, 1);
  background-color: #fff;
}
.arco-input-inner-wrapper{
  background-color: #fff;
}
.arco-input-group-addbefore, .arco-input-group-addafter{
  background-color: #fff;
}
.arco-input-inner-wrapper:focus,.arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
  border-color: #fff;
  box-shadow: #fff;
}
.input-box{
  width: 100%;
  // border-radius: 10px;
  // border: 2px solid #e5e5e5;
  margin-bottom: 30px;
  overflow: hidden;
  .phone_box{
    display: flex;
    align-items: center;
    position: relative;
    .label_pb{
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
      margin-right: 11px;
    }
    .arco-input-inner-wrapper{
      width: 212px;
      height: 33px;
      background-color: rgba(246, 246, 246, 1);
      padding:7px 10px;
      input{
        line-height: 1;
        &::placeholder{
          font-size: 12px;
          color: rgba(153, 153, 153, 1);
        }
      }
    }
  }
  .vcode_box{
    margin-top: 20px;
  }
  .getvcode{
    position: absolute;
    top: 4px;
    right: 4px;
    width: 75px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    background: #02C1A3;
    border-radius: 4px;
    padding:0;
    cursor: pointer;
  }
  .disabled_getcode{
    background-color: #999999;
  }
}
.border{
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}
.download-btn{
  width: 400px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  margin-top: 20px;
  }
  .qrcodelogin{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .arco-input-group-wrapper.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-prefix > svg, .arco-input-group-wrapper.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-suffix > svg{
    font-size: 20px;
  }
  .arco-input-inner-wrapper.arco-input-inner-wrapper-large{
    padding: 0 12px;
  }
  .phoneIcon{
    width: 20px;
    height: 20px;
  }
  .wxchat{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wxchat .tit{
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(34, 34, 34, 1);
  }
  .wxchat .qrcode-box{
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qrcode{
    width: 100%;
    height: 100%;
    background-color: black;
  }
  .tips{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 9px;
    color: rgba(153, 153, 153, 1);
  }
  .btn-box{
    width: 180px;
    height: 44px;
    border-radius: 10px;
    margin-top: 36px;
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
    text-align: center;
    background: rgba(244, 244, 244, 1);
    color: rgba(77, 77, 77, 1);
    cursor: pointer;
  }

  .arco-checkbox-checked .arco-checkbox-mask{
    background-color: rgba(2, 193, 163, 1);
  }

  .arco-btn-outline.arco-btn-disabled {
    background-color: transparent;
    color: rgba(34, 34, 34, 1);
    border: 1px solid rgba(34, 34, 34, 1);
    cursor: not-allowed;
  }
  .arco-btn-outline:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    color:black;
  }

  .fixed{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .mode-box{
    width: 100%;
    font-weight: bolder;
    font-size: 20px;
    color: #31393E;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 43px;
    margin-bottom: 38px;
    .wxlogin{
      margin-right: 20px;
    }
    .check-mode{
      position: relative;
      &::after{
        content: '';
        width: 60px;
        height: 4px;
        background: #02C1A3;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .closed{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 14px;
    right: 25px;
    cursor: pointer;
  }
  .xieyi{
    padding: 20px 34px 30px 34px;
  }
