.App {
  /* text-align: center; */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(126, 184, 214);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cc-effect {
  background-color: bisque;
}

.nav-active {
  color: red;
  font-weight: bold;
}

.sss {
  position: fixed;
  top: 1px;
  left: 1px;
  width: 1px;
  height: 0px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0px;
  display: none;
}
.aside-box{
  height: 100%;
  box-sizing: border-box;
  /* padding:16px; */
}
