
.del_model{
    padding: 0 8px;
    .tit-txt{
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        text-align: left;
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 20px;
    }
    .cont-txt{
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #5A5A5A;
        line-height: 20px;
        text-align: left;
    }
}
.btn_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 44px;
    .cancel_btn{
        width: 100px;
        height: 32px;
        line-height: 29px;
        text-align: center;
        border-radius: 5px;
        border:2px solid #02C1A3;
        font-weight: bold;
        font-size: 13px;
        color: #02C1A3;
        cursor: pointer;
        margin-right: 14px;
    }
    .sure_btn{
        width: 100px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        color: #ffffff;
        cursor: pointer;
    }
}