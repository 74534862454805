
.arco-table-cell{
    text-align: center;
}
.tit{
    padding: 32px 0 16px 0;
    color: #222222;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
}
.con_left{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.first{
    width: 144px;
    height: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding-top: 30px; */
    margin-top: 42px;
}
.headimg{
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F4F4F4;
    border-radius: 50%;
    position: relative;
}
.headimg .head_img_box{
    width: 74px;
    height: 74px;
    overflow: hidden;
    border-radius: 50%;
}
.head_img_box img{
    width: 100%;
    height: 100%;
}
.user_name{
    height: 18px;
    line-height: 18px;
    color: #222222;
    font-family: Source Han Sans CN;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 16px;
}
.vip_type{
    width: 70px;
    height: 16px;
    position: absolute;
    bottom: 0;
    right: -30%;
}
.edit_user_name{
    margin-top: 14px;
    display: flex;
    height: 24px;
    line-height: 24px;
    align-items: center;
    justify-content: center;
    background: #F4F4F4;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
}
.edit_user_name span{
    margin-left: 5px;
}
.second{
    width: 100%;
    margin-top: 60px;
    border-bottom: 1px solid #F4F4F4;
    border-top: 1px solid #F4F4F4;
    padding-bottom: 15px;
}
.third{
    width: 100%;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
}
.count_box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.count_box img{
    width: 22px;
    height: 22px;
}
.count_box .img_box{
    width: 44px;
    height: 44px;
    background: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.count_box p{
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    margin: 10px 0;
}
.count_box .count{
    font-family: SF Pro Text;
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #222222;
}
.arco-pagination-item-active{
    background-color: #F4F4F4!important;
    color: #222222!important;
}
.xzy_modal{

}
.xzy_modal .arco-modal-header{
    height: 40px;
    font-size: 16px;
    line-height: 40px;
}
.xzy_modal .arco-modal-title{
    color: #222222;
    font-weight: bold;
}
.xzy_modal .arco-modal-close-icon{ 
    right: 15px!important;
    top: 15px!important;
}
.xzy_modal .arco-modal-footer{
    border: none;
    padding: 0 16px;
}
.xzy_modal .btns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.xzy_modal .butn{
    width: 106px;
    height: 32px;
    border-radius: 8px;
    line-height: 32px;
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}
.xzy_modal .cancel{
    background: #666666;
}
.xzy_modal .confirm{
    background: #222222;
}
.xzy_modal .arco-modal-content{
    padding: 26px;
}
.xzy_modal .arco-input-inner-wrapper{
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    overflow: hidden;
    padding-left: 0!important;
}
.xzy_modal .arco-input {
    width: 228px;
    height: 36px;
    line-height: 36px;
}


.user-center{
    overflow: hidden;
    padding-left: 60px;
    height: 100%;
    background-image: url('../../../assets/v2/imgs/userbg.png');
    .big-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 9px;
        img{
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }
        p{
            font-weight: 600;
            font-size: 36px;
            color: #31393E;
            line-height: 50px;
            letter-spacing: 1px;
        }
    }  
}

.uc-tit{
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    color: #31393E;
    line-height: 28px;
    position: relative;
    margin: 44px 0 26px 0;
    &::before{
        content: '';
        width: 4px;
        height: 24px;
        background: #02C1A3;
        position: absolute;
        top: 2px;
        left: -14px;
    }
}
.basic{
    margin-left: 34px;
    display: flex;
    align-items:center;
    .img{
        width: 66px;
        height: 66px;
        border-radius: 50%;
        margin-right: 29px;
        .arco-avatar{
            width: 100%;
            height: 100%;
        }
    }
    .bas-info{
        .user-name{
            font-weight: bold;
            font-size: 24px;
            color: #252C3E;
            line-height: 33px;
            letter-spacing: 2px;
            position: relative;
            display: flex;
            align-items: flex-end;
        }
        .user-phone{
            font-weight: 400;
            font-size: 14px;
            color: #252C3E;
            line-height: 20px;
            letter-spacing: 1px;
            margin: 10px 0;
        }
        .user-wx{
            font-weight: 400;
            font-size: 14px;
            color: #252C3E;
            line-height: 20px;
            letter-spacing: 1px;
        }
        .edit{
            margin-left: 10px;
            cursor:pointer;
            // width: 12px;
            // height: 12px;
            // position: absolute;
            // bottom: 0;
            // right: 9px;
            // background-image: url('../../assets/v2/imgs/closed.png');
        }
        .unbind{
            width: 34px;
            height: 19px;
            display: inline-block;
            background: #F1F1F1;
            border-radius: 4px;
            border: 1px solid #CBCBCB;
            font-weight: 400;
            font-size: 10px;
            color: #797979;
            line-height: 19px;
            text-align: center;
            margin-left: 14px;
            cursor:pointer;
        }
    }
}
.vip-info{
    // margin-left: 34px;
    .v-info{
        margin-left: 129px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        .c-label{
            color: #999999;
        }
        .c-value{
            color: #31393E;
            font-weight: bold;
        }
        .c-upbtn{
            display: inline-block;
            width: 34px;
            height: 19px;
            background: linear-gradient( 270deg, #E97C45 0%, #FC6332 100%);
            border-radius: 4px;
            font-weight: bold;
            font-size: 10px;
            color: #FFEDC9;
            line-height: 19px;
            text-align: center;
            margin-left: 14px;
        }
    }
    .vip-level{
        margin-top: 0;
    }
}


.xzy_modal{
    padding: 0 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .titss{
        // display: none;
        width: 100%;
        height: 28px;
        font-weight: 600;
        font-size: 20px;
        color: #31393E;
        line-height: 28px;
        margin: 43px 0 37px 0;
        text-align: center;
    }
    .name-input{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .ni-label{
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
        margin-right: 10px;
    }
    .arco-input-inner-wrapper{
        flex: 1;
        height: 33px;
    }
    .arco-modal-content{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .surebtnn{
        margin-top: 53px;
        width: 100%;
        height: 44px;
        background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
        border-radius: 5px;
        line-height: 44px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 44px;
        letter-spacing: 2px;
    }
    .wx-qrcode{
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}




.vip_model{
    overflow: hidden;
    .arco-modal-content{
        padding: 0;
    }
    .vip-content{
        width: 100%;
        height: 500px;
        display: flex;
        .v-left{
            width: 210px;
            height: 100%;
            padding: 0 22px;
            background: linear-gradient( #FFF5DE 0%, #FFE8BA 100%);
            position: relative;
            .welcom{
                font-weight: 600;
                font-size: 18px;
                color: #6D4304;
                line-height: 25px;
                margin-top: 35px;
            }
            .border-w{
                width: 60px;
                height: 4px;
                background: #6D4304;
                margin-top: 11px;
            }
            .gold-vip{
                margin-top: 37px;
                width: 110px;
                height: 32px;
                background: linear-gradient( 210deg, #A65800 0%, #6D4304 100%);
                font-weight: 600;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 32px;
                text-align: center;
                border-radius: 6px;
                transform: skew(-20deg);
                p{
                    transform: skew(20deg); 
                }
            }
            .equity{
                font-weight: 400;
                font-size: 12px;
                color: #6D4304;
                margin-top: 26px;
                .eq-item{
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;
                }
                .mark{
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-image: url('../../../assets/v2/imgs/checkyellow.png');
                    background-size: 100%;
                    margin-right: 10px;
                }
            }
            .starbg{
                width: 143px;
                height: 116px;
                background-image: url('../../../assets/v2/imgs/starbg.png');
                background-position: right bottom;
                background-size: 100%;
                position: absolute;
                bottom: 0;
                right: 10px;
            }
        }
    
        .v-right{
            flex: 1;
            background: #ffffff;
            .v-tabs{
                width: 100%;
                height: 48px;
                display: flex;
                .v-check{
                    background: #FFF5CB;
                    position: relative;
                    &::after{
                        content: '';
                        width: 50px;
                        height: 48px;
                        background-image: url(../../../assets//v2/imgs/left_yellow.png);
                        background-size: 100%;
                        position: absolute;
                        top: 0;
                        right: -50px;
                    }
                }
                .o-check{
                    background: #FFF5CB;
                    position: relative;
                    &::after{
                        content: '';
                        width: 50px;
                        height: 48px;
                        background-image: url(../../../assets//v2/imgs/right_yellow.png);
                        background-size: 100%;
                        position: absolute;
                        top: 0;
                        left: -50px;
                    }
                }
                .v-tab{
                    width: 50%;
                    height: 100%;
                    line-height: 48px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                    color: #6D4304;
                    cursor: pointer;
                }
            }
            .v-cont{
                margin-top: 30px;
                display: flex;
                .vc-left{
                    width: 366px;
                    padding: 0 33px;
                    .v-cards{
                        display: flex;
                        .v-card{
                            cursor: pointer;
                            width: 145px;
                            height: 152px;
                            box-sizing: border-box;
                            border-radius: 9px;
                            border: 1px solid #E2E2E2;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            .card-name{
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                text-align: center;
                                font-weight: 600;
                                font-size: 18px;
                                color: #FFFFFF;
                                position: relative;
                                overflow: hidden;
                                &::after{
                                    content: '';
                                    width: 67px;
                                    height: 66px;
                                    background-image: url('../../../assets/v2/imgs/vip_bg.png');
                                    background-size: 100%;
                                    position: absolute;
                                    right: 0;
                                    bottom: -31px;
                                    // overflow: hidden;
                                }
                            }
                            .price-tips{
                                flex: 1;
                                backdrop-filter: blur(6px);
                                border-radius: 0px 0px 8px 8px;
                            }
                        }
                        .ca-m{
                            .card-name{
                                background: linear-gradient( 90deg, #FFD087 0%, #FFA356 100%);
                            }
                            .price-tips{
                                background: linear-gradient( 180deg, #FFDBB2 0%, #FFFFFF 25%);
                            }
                        }
                        .ca-y{
                            margin-left: 10px;
                            // background: linear-gradient( 0deg, #FE8B88 0%, #F64A23 100%);
                            .card-name{
                                background: linear-gradient( 90deg, #FE8B88 0%, #F64A23 100%);
                            }
                            .price-tips{
                                background: linear-gradient( 180deg, #FDC3BE 0%, #FFFFFF 25%);
                            }
                        }
                        .v-card-check{
                            border: 2px solid #FF5A34; 
                        }
                        .v-price-box{
                            font-weight: bold;
                            font-size: 24px;
                            color: #ED5300;
                            line-height: 20px;
                            text-align: center;
                            margin-top: 20px;
                            span{
                                font-weight: 400;
                                font-size: 14px;
                            }
                        }
                        .v-tips{
                            margin-top: 7px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #6D4304;
                            line-height: 17px;
                            text-align: center;
                        }
                    }
                    .v-title{
                        height: 16px;
                        line-height: 16px;
                        border-left: 4px solid #02C1A3;
                        padding-left: 10px;
                        font-weight: 500;
                        font-size: 14px;
                        color: #6D4304;
                        margin-bottom: 15px;
                    }
                    .v-buyt{
                        margin-top: 54px;
                        .months{
                            display: flex;
                            .month{
                                margin-left: 11px;
                                width: 65px;
                                height: 28px;
                                border-radius: 4px;
                                border: 1px solid #CECECE;
                                font-weight: 400;
                                font-size: 14px;
                                color: #999999;
                                line-height: 28px;
                                text-align: center;
                                position: relative;
                                cursor: pointer;
                                .sale{
                                    width: 27px;
                                    height: 12px;
                                    font-weight: 500;
                                    font-size: 9px;
                                    color: #FFFFFF;
                                    line-height: 12px;
                                    text-align: center;
                                    background: linear-gradient( 270deg, #EE933B 0%, #E84E27 100%);
                                    border-radius: 2px 2px 2px 0px;
                                    position: absolute;
                                    top: -8px;
                                    right: -1px;
                                    z-index: 1;
                                }
                            }
                            .check-month{
                                background: #DBFFED;
                                border: 1px solid #02C1A3;
                                color: #02C1A3;
                                position: relative;
                                &::after{
                                    content: '';
                                    width: 13px;
                                    height: 12px;
                                    background-image: url('../../../assets/v2/imgs/jiaobiao.png');
                                    background-size: 100%;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                        .number-input{
                            input{
                                width: 208px;
                                height: 28px;
                                border-radius: 4px;
                                border: 1px solid #CECECE;
                                padding: 4px 14px;
                                color: #000000;
                                &::placeholder{
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #DCDCDC;
                                }
                            }
                        }
                    }
                    .v-coupon{
                        margin-top: 24px;
                        .coup-input{
                            display: flex;
                            .input{
                                width: 208px;
                                height: 28px;
                                border-radius: 4px;
                                border: 1px solid #CECECE;
                                padding: 4px 14px;
                                color: #000000;
                                &::placeholder{
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #DCDCDC;
                                }
                            }
                            .check-btn{
                                width: 65px;
                                height: 28px;
                                background: #02C1A3;
                                border-radius: 4px;
                                line-height: 28px;
                                text-align: center;
                                font-weight: 500;
                                font-size: 14px;
                                color: #FFFFFF;
                                margin-left: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .vc-right{
                    width: 230px;
                    height: 391px;
                    background: #FFF9EF;
                    border-radius: 9px;
                    padding: 20px 25px;
                    .v-title{
                        height: 16px;
                        line-height: 16px;
                        border-left: 4px solid #02C1A3;
                        padding-left: 6px;
                        font-weight: 600;
                        font-size: 14px;
                        color: #6D4304;
                        margin-bottom: 4px;
                    }
                    .v-detail{
                        .v-de-item{
                            height: 37px;
                            line-height: 37px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-weight: 400;
                            font-size: 12px;
                            color: #6D4304;
                            border-bottom: 1px solid #F6EBD9;
                        }
                    }
                    .wx-pay-code{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .qrcode-box{
                            margin-top: 24px;
                            width: 80px;
                            height: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .qrcode{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .pay-name{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 7px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #6D4304;
                            img{
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                            }
                        }
                        .need-pay{
                            font-weight: 400;
                            font-size: 12px;
                            color: #6D4304;
                            margin-top: 5px;
                           .rel-price{
                                font-size: 24px;
                                color: rgba(237, 83, 0, 1);
                                font-weight: bold;
                           }
                           .unit-yuan{
                                color: rgba(236, 83, 2, 1);
                           }
                        }
                    }
                }
            }
        }
    }
}
.first-tit{
    margin-top: 9px;
}
.mini-txt{
    font-size: 12px;
}
.number-input .arco-input-inner-wrapper{
    padding: 0!important;
}