.main {
  height: 100%;
}

.kong {
  position: fixed;
  top: 1px;
  left: 1px;
  width: 1px;
  height: 0px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0px;
  display: none;
}
.bg-secondary{
  background-color: #f4f4f4;
}
.ask{
  background: #F5EFDA;
  color: #222222;
  position: relative;
  border-radius: 15px 15px 0 15px;
}
.ask:after{
  width: 8px;
  height: 30px;
  content: '';
  background-image: url(../../assets/askbg.png);
  position: absolute;
  right: -8px;
  bottom: 0;
}
.answer{
  background: #E8DAF5;
  color: #222222;
  position: relative;
  border-radius: 15px 15px 15px 0;
  min-height: 40px;
}
.answer:after{
  width: 8px;
  height: 30px;
  content: '';
  background-image: url(../../assets/answerbg.png);
  position: absolute;
  left: -8px;
  bottom: 0;
}
.loading_xzy {
  animation: rotate-animation 2s linear infinite; /* 无限次重复播放动画 */
  display: inline-block;
  margin-right: 5px;
}

@keyframes rotate-animation {
  0% { transform: rotate(0deg); } /* 初始状态 */
  100% { transform: rotate(360deg); } /* 结束状态 */
}
.send_btn{
  background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
  width: 106px;
  height: 44px;
  border-radius: 10px;
  line-height: 44px;
  text-align: center;
}
.cancel_btn{
  width: 106px;
  height: 44px;
  line-height: 44px;
  border-radius: 10px;
  text-align: center;
  border:2px solid #02C1A3;
}


#myTextarea::-webkit-input-placeholder{
  height: 25px;line-height: 25px
}    /* 使用webkit内核的浏览器 */
#myTextarea:-moz-placeholder{
  height: 25px;line-height: 25px
}                  /* Firefox版本4-18 */
#myTextarea::-moz-placeholder{
  height: 25px;line-height: 25px
}                  /* Firefox版本19+ */
#myTextarea:-ms-input-placeholder{
  height: 25px;line-height: 25px
}
.history_content{
  padding: 10px;
}
.history_content_scroll{
  overflow-y: scroll;
  height: 450px;
  border-radius: 10px;
}
.search_history{
  height: 38px;
  background-color: #ffffff;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px
}
.history_modal .arco-modal-header{
  border: none;
}
.history_modal .arco-modal-content{
  padding: 0 16px;
}
.date_history{
  text-align: center;
  color: #999999;
}
.history_item{
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #F4F4F4 inset;
  /* margin-top: 16px; */
  padding: 14px 22px;
  box-sizing: border-box;
}

.relative{
  position: relative;
}
.select-li:hover{
  background-color:#ffffff;
}

.main{
  display: flex;
}
.duihua{
  flex: 1;
 
}
.duomoxing{
  display: flex;
}
.add-model{
  width: 260px;
  background: #FFFFFF;
  padding: 40px 0;
  .add-mod-top{
    width: 100%;
    padding: 0 16px;
  }
  .add{
    width: 228px;
    height: 40px;
    background: #DBFFED;
    border-radius: 4px;
    border: 1px solid #02C1A3;
    font-weight: 600;
    font-size: 14px;
    color: #02C1A3;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .d-model{
    font-weight: 600;
    font-size: 14px;
    color: #31393E;
    line-height: 20px;
    margin-top: 20px;
  }
  .d-tips{
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-top: 15px;
  }
  .models-box{
    padding-left: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .ai-box{
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .ai-mod{
      width: 40px;
      height: 40px;
      border: 1px solid #F2F2F2;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img{
        width: 30px;
        height: 30px;
      }
      .del_icon{
        display: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: -5px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      &:hover .del_icon{
        display: block;
      }
    }
    .ai-name{
      width: 40px;
      height: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 10px;
      color: #252C3E;
      line-height: 14px;
      margin-top: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.duihua-tools-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .stop-gen{
    width: 80px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    img{
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    p{
      font-weight: 400;
      font-size: 10px;
      color: #999999;
      line-height: 14px;
    }
  }
  .send-input{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 94px;
  }
  .textarea-box{
    width: 100%;
    min-height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(216,216,216,0.5);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px 0 31px;
    img{
      width: 43px;
      height: 43px;
    }
    #myTextarea{
      background-color: #ffffff;
      resize: none;
      border: none;
    }
  }
  .duihua-tips{
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-top: 10px;
  }
}
.duihua-box{
  height: 100%;
  display: flex;
  flex-direction: column;
}
#scrollDiv{
  flex: 1;
  display: flex;
  padding-bottom: 20px;
  .one_chat{
    flex: 1;
    border-right: 1px solid #E0E0E0;
  }
}
.ai{
  padding: 0 54px;
  margin-bottom: 20px;
  // flex: 1;
  .touxiang{
    display: flex;
    align-items: center;
    overflow: hidden;
    img{
      width: 30px;
      height: 30px;
    }
    .ai-name{
      flex: 1;
      margin-top: -7px;
      margin-left: 13px;
      font-weight: 600;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
    }
  }
}
.user_ask{
  padding-left: 94px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .cont_html_box{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .cont_html{
      display: inline-block;
      margin-right: 9px;
      // width: %;
      padding: 10px 26px;
      font-weight: 600;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 20px;
      background: #02C1A3;
      box-shadow: 0px 0px 8px 0px rgba(216,216,216,0.5);
      border-radius: 10px;
    }
  }
}
.ai_answer{
  padding-right: 94px;
  .cont_html_box{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    .cont_html{
      display: inline-block;
      margin-top: 5px;
      margin-left: 40px;
      padding: 10px 26px;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(216,216,216,0.5);
      border-radius: 10px;
    }
    .ai_self{
      max-width: 100%;
      .need{
        font-weight: 600;
        font-size: 18px;
        color: #31393E;
        line-height: 25px;
        text-align: left;
      }
      .plans{
        display: flex;
        flex-wrap: wrap;
      }
      .plan{
        background: #F5F5F5;
        border-radius: 4px;
        padding: 5px 41px;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        margin-top: 15px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.thinking {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cont_tools{
  margin:11px 28px 0 40px;
  display: flex;
  img{
    width: 13px;
    height: 13px;
    margin-right: 12px;
    cursor: pointer;
  }
}
 
