.allchat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 94px 0 71px;
    height: 100%;
    .big-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 10px;
        img{
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }
        p{
            font-weight: 600;
            font-size: 36px;
            color: #31393E;
            line-height: 50px;
            letter-spacing: 1px;
        }
    } 
    .chats{
        width: 100%;
        flex: 1;
        overflow-y: scroll;
        .chat-item{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .ch-logo{
                width: 60px;
                height: 60px;
                background: #FFFFFF;
                border-radius: 4px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 60px;
                    height: 60px;
                }
            }
            .ch-info{
                flex: 1;
                height: 101px;
                border-bottom: 2px solid #DEDEDE;
                margin-left: 20px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .ch-name{
                    font-weight: 600;
                    font-size: 16px;
                    color: #31393E;
                    line-height: 22px;
                }
                .ch-ask{
                    font-weight: 600;
                    font-size: 16px;
                    color: #31393E;
                    line-height: 22px;
                }
                .ch-answer{
                    font-weight: 400;
                    font-size: 12px;
                    color: #999999;
                    line-height: 17px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
                .ch-date{
                    font-weight: 600;
                    font-size: 12px;
                    color: #31393E;
                    line-height: 17px;
                    position: absolute;
                    top: 24px;
                    right: 0;
                }
            }
        }
    }
}