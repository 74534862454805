
*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,
:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal
}

body {
  margin: 0;
  line-height: inherit;
  /* opacity: 0.2; */
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit
}

a {
  color: inherit;
  text-decoration: inherit
}

b,
strong {
  font-weight: bolder
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0
}

button,
select {
  text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none
}

:-moz-focusring {
  outline: auto
}

:-moz-ui-invalid {
  box-shadow: none
}

progress {
  vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0
}

fieldset {
  margin: 0;
  padding: 0
}

legend {
  padding: 0
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0
}

textarea {
  resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af
}

button,
[role=button] {
  cursor: pointer
}

:disabled {
  cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle
}

img,
video {
  max-width: 100%;
  height: auto
}

[hidden] {
  display: none
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial
}

:root {
  opacity: .88
}

*,
:before,
:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.pointer-events-none {
  pointer-events: none
}

.invisible {
  visibility: hidden
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  inset: 0px
}

.inset-y-0 {
  top: 0px;
  bottom: 0px
}

.left-0 {
  left: 0px
}

.right-0 {
  right: 0px
}

.right-4 {
  right: 1rem
}

.right-\[-8px\] {
  right: -8px
}

.top-4 {
  top: 1rem
}

.top-\[-8px\] {
  top: -8px
}

.isolate {
  isolation: isolate
}

.z-10 {
  z-index: 10
}

.z-50 {
  z-index: 50
}

.m-5 {
  margin: 1.25rem
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.-ml-px {
  margin-left: -1px
}

.mb-0 {
  margin-bottom: 0
}

.mb-1 {
  margin-bottom: .25rem
}

.mb-2 {
  margin-bottom: .5rem
}

.mb-3 {
  margin-bottom: .75rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-\[5px\] {
  margin-bottom: 5px
}

.ml-1 {
  margin-left: .25rem
}

.ml-2 {
  margin-left: .5rem
}

.ml-auto {
  margin-left: auto
}

.mr-1 {
  margin-right: .25rem
}

.mr-2 {
  margin-right: .5rem
}

.mr-\[10px\] {
  margin-right: 10px
}

.mr-\[6px\] {
  margin-right: 6px
}

.mt-1 {
  margin-top: .25rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mt-2 {
  margin-top: .5rem
}

.mt-3 {
  margin-top: .75rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-9 {
  margin-top: 2.25rem
}

.mt-\[12px\] {
  margin-top: 12px
}

.mt-auto {
  margin-top: auto
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-10 {
  height: 2.5rem
}

.h-11 {
  height: 2.75rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-8 {
  height: 2rem
}

.h-9 {
  height: 2.25rem
}

.h-\[18px\] {
  height: 18px
}

.h-\[1px\] {
  height: 1px
}

.h-\[250px\] {
  height: 250px
}

.h-\[400px\] {
  height: 400px
}

.h-full {
  height: 100%
}

.h-px {
  height: 1px
}

.h-screen {
  height: 100vh
}

.max-h-60 {
  max-height: 15rem
}

.max-h-\[300px\] {
  max-height: 300px
}

.max-h-full {
  max-height: 100%
}

.max-h-screen {
  max-height: 100vh
}

.min-h-\[300px\] {
  min-height: 300px
}

.min-h-\[400px\] {
  min-height: 400px
}

.w-10 {
  width: 2.5rem
}

.w-11\/12 {
  width: 91.666667%
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-6 {
  width: 1.5rem
}

.w-8 {
  width: 2rem
}

.w-9 {
  width: 2.25rem
}

.w-\[1000px\] {
  width: 1000px
}

.w-\[150px\] {
  width: 150px
}

.w-\[18px\] {
  width: 18px
}

.w-\[200px\] {
  width: 200px
}

.w-\[230px\] {
  width: 230px
}

.w-\[250px\] {
  width: 250px
}

.w-\[300px\] {
  width: 300px
}

.w-\[30px\] {
  width: 30px
}

.w-\[400px\] {
  width: 400px
}

.w-\[500px\] {
  width: 500px
}

.w-\[600px\] {
  width: 600px
}

.w-\[70\%\] {
  width: 70%
}

.w-\[79px\] {
  width: 79px
}

.w-\[80\%\] {
  width: 80%
}

.w-\[800px\] {
  width: 800px
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.min-w-0 {
  min-width: 0px
}

.min-w-\[150px\] {
  min-width: 150px
}

.min-w-max {
  min-width: -moz-max-content;
  min-width: max-content
}

.max-w-\[700px\] {
  max-width: 700px
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content
}

.max-w-xs {
  max-width: 20rem
}

.flex-1 {
  flex: 1 1 0%
}

.flex-shrink-0,
.shrink-0 {
  flex-shrink: 0
}

.grow {
  flex-grow: 1
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-default {
  cursor: default
}

.cursor-not-allowed {
  cursor: not-allowed
}

.cursor-pointer {
  cursor: pointer
}

.cursor-wait {
  cursor: wait
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.resize-none {
  resize: none
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr)
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-\[auto_1fr\] {
  grid-template-columns: auto 1fr
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-1 {
  gap: .25rem
}

.gap-2 {
  gap: .5rem
}

.gap-3 {
  gap: .75rem
}

.gap-4 {
  gap: 1rem
}

.gap-5 {
  gap: 1.25rem
}

.gap-\[10px\] {
  gap: 10px
}

.gap-\[13px\] {
  gap: 13px
}

.gap-\[5px\] {
  gap: 5px
}

.gap-\[6px\] {
  gap: 6px
}

.space-x-3>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-2>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
}

.space-y-4>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.self-end {
  align-self: flex-end
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.whitespace-nowrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.rounded {
  border-radius: .25rem
}

.rounded-2xl {
  border-radius: 1rem
}

.rounded-3xl {
  border-radius: 1.5rem
}

.rounded-\[10px\] {
  border-radius: 10px
}

.rounded-\[15px\] {
  border-radius: 15px
}

.rounded-\[20px\] {
  border-radius: 20px
}

.rounded-\[5px\] {
  border-radius: 5px
}

.rounded-\[6px\] {
  border-radius: 6px
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: .5rem
}

.rounded-md {
  border-radius: .375rem
}

.rounded-sm {
  border-radius: .125rem
}

.rounded-xl {
  border-radius: .75rem
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-b {
  border-bottom-width: 1px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-\[\#ffffff4d\] {
  border-color: #ffffff4d
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.border-primary-border {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-border) / var(--tw-border-opacity))
}

.border-transparent {
  border-color: transparent
}

.border-b-slate-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(241 245 249 / var(--tw-border-opacity))
}

.bg-\[\#00000014\] {
  background-color: #00000014
}

.bg-\[\#FAE387\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 227 135 / var(--tw-bg-opacity))
}

.bg-\[\#e6e7e8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 231 232 / var(--tw-bg-opacity))
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-black\/30 {
  background-color: #0000004d
}

.bg-black\/50 {
  background-color: #00000080
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-primary-background {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-background) / var(--tw-bg-opacity))
}

.bg-primary-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary-blue) / var(--tw-bg-opacity))
}

.bg-primary-border {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-border) / var(--tw-bg-opacity))
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-secondary) / var(--tw-bg-opacity))
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-opacity-20 {
  --tw-bg-opacity: .2
}

.bg-opacity-40 {
  --tw-bg-opacity: .4
}

.bg-opacity-90 {
  --tw-bg-opacity: .9
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain
}

.\!p-0 {
  padding: 0 !important
}

.p-2 {
  padding: .5rem
}

.p-3 {
  padding: .75rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-\[50px\] {
  padding: 50px
}

.p-\[6px\] {
  padding: 6px
}

.\!py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px
}

.px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px
}

.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px
}

.pb-1 {
  padding-bottom: .25rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-\[10px\] {
  padding-bottom: 10px
}

.pl-3 {
  padding-left: .75rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pr-2 {
  padding-right: .5rem
}

.pr-4 {
  padding-right: 1rem
}

.pr-8 {
  padding-right: 2rem
}

.pr-9 {
  padding-right: 2.25rem
}

.pt-2 {
  padding-top: .5rem
}

.pt-3 {
  padding-top: .75rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.\!text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.text-\[40px\] {
  font-size: 40px
}

.text-\[50px\] {
  font-size: 50px
}

.text-\[64px\] {
  font-size: 64px
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.italic {
  font-style: italic
}

.leading-6 {
  line-height: 1.5rem
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.tracking-widest {
  letter-spacing: .1em
}

.text-\[\#303030\] {
  --tw-text-opacity: 1;
  color: rgb(48 48 48 / var(--tw-text-opacity))
}

.text-\[\#cc0000\] {
  --tw-text-opacity: 1;
  color: rgb(204 0 0 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity))
}

.text-light-text {
  --tw-text-opacity: 1;
  color: rgb(var(--light-text) / var(--tw-text-opacity))
}

.text-primary-blue {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary-blue) / var(--tw-text-opacity))
}

.text-primary-text {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-text) / var(--tw-text-opacity))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-secondary-text {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-text) / var(--tw-text-opacity))
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity))
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.line-through {
  text-decoration-line: line-through
}

.opacity-0 {
  opacity: 0
}

.opacity-100 {
  opacity: 1
}

.opacity-30 {
  opacity: .3
}

.opacity-50 {
  opacity: .5
}

.opacity-70 {
  opacity: .7
}

.opacity-80 {
  opacity: .8
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-\[hsl\(206_22\%_7\%_\/_35\%\)_0px_10px_38px_-10px\,_hsl\(206_22\%_7\%_\/_20\%\)_0px_10px_20px_-15px\] {
  --tw-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  --tw-shadow-colored: 0px 10px 38px -10px var(--tw-shadow-color), 0px 10px 20px -15px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-inset {
  --tw-ring-inset: inset
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity))
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
}

.ring-primary-border {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--primary-border) / var(--tw-ring-opacity))
}

.ring-opacity-5 {
  --tw-ring-opacity: .05
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s
}

.duration-100 {
  transition-duration: .1s
}

.duration-200 {
  transition-duration: .2s
}

.duration-75 {
  transition-duration: 75ms
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.will-change-\[transform\,opacity\] {
  will-change: transform, opacity
}

.scrollbar-thin {
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial)
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius)
}

.scrollbar-thin::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track))
}

.scrollbar-thin::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)))
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius)
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb))
}

.scrollbar-thin::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)))
}

.scrollbar-thin::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
  border-radius: var(--scrollbar-corner-radius)
}

.scrollbar-thin::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner))
}

.scrollbar-thin::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)))
}

.scrollbar-thin {
  scrollbar-width: thin
}

.scrollbar-thin::-webkit-scrollbar {
  display: block;
  width: 8px;
  height: 8px
}

.scrollbar-none {
  scrollbar-width: none
}

.scrollbar-none::-webkit-scrollbar {
  display: none
}

html,
body {
  font-family: Inter, "system-ui"
}

@supports (font-variation-settings: normal) {

  html,
  body {
    font-family: Inter var, "system-ui"
  }
}

body {
  font-size: 100%
}

:focus-visible {
  outline: none
}

:root.light {
  color-scheme: light;
  --color-primary-blue: 73 135 252;
  --color-secondary: 242 242 242;
  --color-primary-purple: 103 86 189;
  --primary-background: 255 255 255;
  --primary-text: 48 48 48;
  --secondary-text: 128 128 128;
  --light-text: 190 190 190;
  --primary-border: 237 237 237
}

:root.light pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}

:root.light code.hljs {
  padding: 3px 5px
}

:root.light .hljs {
  color: #24292e;
  background: #fff
}

:root.light .hljs-doctag,
:root.light .hljs-keyword,
:root.light .hljs-meta .hljs-keyword,
:root.light .hljs-template-tag,
:root.light .hljs-template-variable,
:root.light .hljs-type,
:root.light .hljs-variable.language_ {
  color: #d73a49
}

:root.light .hljs-title,
:root.light .hljs-title.class_,
:root.light .hljs-title.class_.inherited__,
:root.light .hljs-title.function_ {
  color: #6f42c1
}

:root.light .hljs-attr,
:root.light .hljs-attribute,
:root.light .hljs-literal,
:root.light .hljs-meta,
:root.light .hljs-number,
:root.light .hljs-operator,
:root.light .hljs-selector-attr,
:root.light .hljs-selector-class,
:root.light .hljs-selector-id,
:root.light .hljs-variable {
  color: #005cc5
}

:root.light .hljs-meta .hljs-string,
:root.light .hljs-regexp,
:root.light .hljs-string {
  color: #032f62
}

:root.light .hljs-built_in,
:root.light .hljs-symbol {
  color: #e36209
}

:root.light .hljs-code,
:root.light .hljs-comment,
:root.light .hljs-formula {
  color: #6a737d
}

:root.light .hljs-name,
:root.light .hljs-quote,
:root.light .hljs-selector-pseudo,
:root.light .hljs-selector-tag {
  color: #22863a
}

:root.light .hljs-subst {
  color: #24292e
}

:root.light .hljs-section {
  color: #005cc5;
  font-weight: 700
}

:root.light .hljs-bullet {
  color: #735c0f
}

:root.light .hljs-emphasis {
  color: #24292e;
  font-style: italic
}

:root.light .hljs-strong {
  color: #24292e;
  font-weight: 700
}

:root.light .hljs-addition {
  color: #22863a;
  background-color: #f0fff4
}

:root.light .hljs-deletion {
  color: #b31d28;
  background-color: #ffeef0
}

:root.dark {
  color-scheme: dark;
  --color-primary-blue: 50 104 206;
  --color-secondary: 46 46 46;
  --color-primary-purple: 57 41 141;
  --primary-background: 25 25 25;
  --primary-text: 223 223 223;
  --secondary-text: 127 127 127;
  --light-text: 79 79 79;
  --primary-border: 53 53 53
}

:root.dark pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}

:root.dark code.hljs {
  padding: 3px 5px
}

:root.dark .hljs {
  color: #c9d1d9;
  background: #0d1117
}

:root.dark .hljs-doctag,
:root.dark .hljs-keyword,
:root.dark .hljs-meta .hljs-keyword,
:root.dark .hljs-template-tag,
:root.dark .hljs-template-variable,
:root.dark .hljs-type,
:root.dark .hljs-variable.language_ {
  color: #ff7b72
}

:root.dark .hljs-title,
:root.dark .hljs-title.class_,
:root.dark .hljs-title.class_.inherited__,
:root.dark .hljs-title.function_ {
  color: #d2a8ff
}

:root.dark .hljs-attr,
:root.dark .hljs-attribute,
:root.dark .hljs-literal,
:root.dark .hljs-meta,
:root.dark .hljs-number,
:root.dark .hljs-operator,
:root.dark .hljs-selector-attr,
:root.dark .hljs-selector-class,
:root.dark .hljs-selector-id,
:root.dark .hljs-variable {
  color: #79c0ff
}

:root.dark .hljs-meta .hljs-string,
:root.dark .hljs-regexp,
:root.dark .hljs-string {
  color: #a5d6ff
}

:root.dark .hljs-built_in,
:root.dark .hljs-symbol {
  color: #ffa657
}

:root.dark .hljs-code,
:root.dark .hljs-comment,
:root.dark .hljs-formula {
  color: #8b949e
}

:root.dark .hljs-name,
:root.dark .hljs-quote,
:root.dark .hljs-selector-pseudo,
:root.dark .hljs-selector-tag {
  color: #7ee787
}

:root.dark .hljs-subst {
  color: #c9d1d9
}

:root.dark .hljs-section {
  color: #1f6feb;
  font-weight: 700
}

:root.dark .hljs-bullet {
  color: #f2cc60
}

:root.dark .hljs-emphasis {
  color: #c9d1d9;
  font-style: italic
}

:root.dark .hljs-strong {
  color: #c9d1d9;
  font-weight: 700
}

:root.dark .hljs-addition {
  color: #aff5b4;
  background-color: #033a16
}

:root.dark .hljs-deletion {
  color: #ffdcd7;
  background-color: #67060c
}

.placeholder\:text-gray-400::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.placeholder\:text-slate-400::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity))
}

.placeholder\:text-slate-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity))
}

.group:hover .group-hover\:visible {
  visibility: visible
}

.group:hover .group-hover\:block {
  display: block
}

.aria-selected\:bg-slate-100[aria-selected=true] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none
}

@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.data-\[state\=delayed-open\]\:data-\[side\=bottom\]\:animate-slideUpAndFade[data-side=bottom][data-state=delayed-open] {
  animation: slideUpAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: translate(2px)
  }

  to {
    opacity: 1;
    transform: translate(0)
  }
}

.data-\[state\=delayed-open\]\:data-\[side\=left\]\:animate-slideRightAndFade[data-side=left][data-state=delayed-open] {
  animation: slideRightAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: translate(2px)
  }

  to {
    opacity: 1;
    transform: translate(0)
  }
}

.data-\[state\=delayed-open\]\:data-\[side\=right\]\:animate-slideLeftAndFade[data-side=right][data-state=delayed-open] {
  animation: slideLeftAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-2px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.data-\[state\=delayed-open\]\:data-\[side\=top\]\:animate-slideDownAndFade[data-side=top][data-state=delayed-open] {
  animation: slideDownAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

.data-\[state\=open\]\:bg-slate-100[data-state=open] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5
}

.ui-active\:bg-primary-blue[data-headlessui-state~=active] {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary-blue) / var(--tw-bg-opacity))
}

.ui-active\:text-white[data-headlessui-state~=active] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

:where([data-headlessui-state~="active"]) .ui-active\:bg-primary-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary-blue) / var(--tw-bg-opacity))
}

:where([data-headlessui-state~="active"]) .ui-active\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ui-not-active\:text-secondary-text[data-headlessui-state]:not([data-headlessui-state~="active"]) {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-text) / var(--tw-text-opacity))
}

:where([data-headlessui-state]:not([data-headlessui-state~="active"])) .ui-not-active\:text-secondary-text:not([data-headlessui-state]) {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-text) / var(--tw-text-opacity))
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.hover\:text-primary-text:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-text) / var(--tw-text-opacity))
}

.hover\:opacity-100:hover {
  opacity: 1
}

.hover\:opacity-80:hover {
  opacity: .8
}

.focus\:z-10:focus {
  z-index: 10
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity))
}

.focus\:ring-slate-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity))
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px
}

.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed
}

.disabled\:opacity-50:disabled {
  opacity: .5
}

:is(.dark .dark\:border-gray-500) {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity))
}

:is(.dark .dark\:border-b-slate-700) {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(51 65 85 / var(--tw-border-opacity))
}

:is(.dark .dark\:bg-\[\#444a5354\]) {
  background-color: #444a5354
}

:is(.dark .dark\:bg-\[\#ffffff26\]) {
  background-color: #ffffff26
}

:is(.dark .dark\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

:is(.dark .dark\:bg-primary-blue) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary-blue) / var(--tw-bg-opacity))
}

:is(.dark .dark\:bg-slate-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity))
}

:is(.dark .dark\:bg-slate-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity))
}

:is(.dark .dark\:bg-slate-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity))
}

:is(.dark .dark\:text-gray-100) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity))
}

:is(.dark .dark\:text-primary-text) {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-text) / var(--tw-text-opacity))
}

:is(.dark .dark\:text-slate-400) {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity))
}

:is(.dark .dark\:text-slate-50) {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity))
}

:is(.dark .dark\:aria-selected\:bg-slate-700[aria-selected="true"]) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity))
}

:is(.dark .dark\:data-\[state\=open\]\:bg-slate-800[data-state=open]) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity))
}

:is(.dark .dark\:focus\:ring-slate-400:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity))
}

:is(.dark .dark\:focus\:ring-offset-slate-900:focus) {
  --tw-ring-offset-color: #0f172a
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex
  }

  .sm\:max-w-lg {
    max-width: 32rem
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:space-x-2>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-3>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-0>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .sm\:rounded-lg {
    border-radius: .5rem
  }

  .sm\:text-left {
    text-align: left
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem
  }

  .sm\:leading-6 {
    line-height: 1.5rem
  }
}

.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading] {
  padding-left: .5rem;
  padding-right: .5rem
}

.\[\&_\[cmdk-group-heading\]\]\:pb-1\.5 [cmdk-group-heading] {
  padding-bottom: .375rem
}

.\[\&_\[cmdk-group-heading\]\]\:text-sm [cmdk-group-heading] {
  font-size: .875rem;
  line-height: 1.25rem
}

.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading] {
  font-weight: 500
}

.\[\&_\[cmdk-group-heading\]\]\:font-semibold [cmdk-group-heading] {
  font-weight: 600
}

.\[\&_\[cmdk-group-heading\]\]\:text-slate-500 [cmdk-group-heading] {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity))
}

.\[\&_\[cmdk-group-heading\]\]\:text-slate-900 [cmdk-group-heading] {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity))
}

:is(.dark .\[\&_\[cmdk-group-heading\]\]\:dark\:text-slate-300) [cmdk-group-heading] {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity))
}

.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group] {
  padding-left: .5rem;
  padding-right: .5rem
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg {
  height: 1.25rem
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg {
  width: 1.25rem
}

.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input] {
  height: 3rem
}

.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item] {
  padding-left: .5rem;
  padding-right: .5rem
}

.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item] {
  padding-top: .75rem;
  padding-bottom: .75rem
}

.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg {
  height: 1.25rem
}

.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg {
  width: 1.25rem
}

.\[\&_\[dialog-overlay\]\]\:bg-red-100 [dialog-overlay] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity))
}

@media (prefers-color-scheme: dark) {
  .markdown-body {
    color-scheme: dark;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #c9d1d9;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-fg-default: #c9d1d9;
    --color-fg-muted: #8b949e;
    --color-fg-subtle: #6e7681;
    --color-canvas-default: #0d1117;
    --color-canvas-subtle: #161b22;
    --color-border-default: #30363d;
    --color-border-muted: #21262d;
    --color-neutral-muted: rgba(110, 118, 129, .4);
    --color-accent-fg: #58a6ff;
    --color-accent-emphasis: #1f6feb;
    --color-attention-subtle: rgba(187, 128, 9, .15);
    --color-danger-fg: #f85149
  }
}

@media (prefers-color-scheme: light) {
  .markdown-body {
    color-scheme: light;
    --color-prettylights-syntax-comment: #6e7781;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-entity: #8250df;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #116329;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-fg-default: #24292f;
    --color-fg-muted: #57606a;
    --color-fg-subtle: #6e7781;
    --color-canvas-default: #ffffff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: hsla(210, 18%, 87%, 1);
    --color-neutral-muted: rgba(175, 184, 193, .2);
    --color-accent-fg: #0969da;
    --color-accent-emphasis: #0969da;
    --color-attention-subtle: #fff8c5;
    --color-danger-fg: #cf222e
  }
}

.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word
}

.markdown-body .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom
}

.markdown-body h1:hover .anchor .octicon-link:before,
.markdown-body h2:hover .anchor .octicon-link:before,
.markdown-body h3:hover .anchor .octicon-link:before,
.markdown-body h4:hover .anchor .octicon-link:before,
.markdown-body h5:hover .anchor .octicon-link:before,
.markdown-body h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: " ";
  display: inline-block;
  background-color: currentColor;
  -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>")
}

.markdown-body details,
.markdown-body figcaption,
.markdown-body figure {
  display: block
}

.markdown-body summary {
  display: list-item
}

.markdown-body [hidden] {
  display: none !important
}

.markdown-body a {
  background-color: transparent;
  color: var(--color-accent-fg);
  text-decoration: none
}

.markdown-body abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

.markdown-body b,
.markdown-body strong {
  font-weight: var(--base-text-weight-semibold, 600)
}

.markdown-body dfn {
  font-style: italic
}

.markdown-body h1 {
  margin: .67em 0;
  font-weight: var(--base-text-weight-semibold, 600);
  padding-bottom: .3em;
  font-size: 2em;
  border-bottom: 1px solid var(--color-border-muted)
}

.markdown-body mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-fg-default)
}

.markdown-body small {
  font-size: 90%
}

.markdown-body sub,
.markdown-body sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

.markdown-body sub {
  bottom: -.25em
}

.markdown-body sup {
  top: -.5em
}

.markdown-body img {
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default)
}

.markdown-body code,
.markdown-body kbd,
.markdown-body pre,
.markdown-body samp {
  font-family: monospace;
  font-size: 1em
}

.markdown-body figure {
  margin: 1em 40px
}

.markdown-body hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid var(--color-border-muted);
  height: .25em;
  padding: 0;
  margin: 24px 0;
  background-color: var(--color-border-default);
  border: 0
}

.markdown-body input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

.markdown-body [type=button],
.markdown-body [type=reset],
.markdown-body [type=submit] {
  -webkit-appearance: button
}

.markdown-body [type=checkbox],
.markdown-body [type=radio] {
  box-sizing: border-box;
  padding: 0
}

.markdown-body [type=number]::-webkit-inner-spin-button,
.markdown-body [type=number]::-webkit-outer-spin-button {
  height: auto
}

.markdown-body [type=search]::-webkit-search-cancel-button,
.markdown-body [type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

.markdown-body ::-webkit-input-placeholder {
  color: inherit;
  opacity: .54
}

.markdown-body ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

.markdown-body a:hover {
  text-decoration: underline
}

.markdown-body ::-moz-placeholder {
  color: var(--color-fg-subtle);
  opacity: 1
}

.markdown-body ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1
}

.markdown-body hr:before {
  display: table;
  content: ""
}

.markdown-body hr:after {
  display: table;
  clear: both;
  content: ""
}

.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  overflow: auto
}

.markdown-body td,
.markdown-body th {
  padding: 0
}

.markdown-body details summary {
  cursor: pointer
}

.markdown-body details:not([open])>*:not(summary) {
  display: none !important
}

.markdown-body a:focus,
.markdown-body [role=button]:focus,
.markdown-body input[type=radio]:focus,
.markdown-body input[type=checkbox]:focus {
  outline: 2px solid var(--color-accent-fg);
  outline-offset: -2px;
  box-shadow: none
}

.markdown-body a:focus:not(:focus-visible),
.markdown-body [role=button]:focus:not(:focus-visible),
.markdown-body input[type=radio]:focus:not(:focus-visible),
.markdown-body input[type=checkbox]:focus:not(:focus-visible) {
  outline: solid 1px transparent
}

.markdown-body a:focus-visible,
.markdown-body [role=button]:focus-visible,
.markdown-body input[type=radio]:focus-visible,
.markdown-body input[type=checkbox]:focus-visible {
  outline: 2px solid var(--color-accent-fg);
  outline-offset: -2px;
  box-shadow: none
}

.markdown-body a:not([class]):focus,
.markdown-body a:not([class]):focus-visible,
.markdown-body input[type=radio]:focus,
.markdown-body input[type=radio]:focus-visible,
.markdown-body input[type=checkbox]:focus,
.markdown-body input[type=checkbox]:focus-visible {
  outline-offset: 0
}

.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted)
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: var(--base-text-weight-semibold, 600);
  line-height: 1.25
}

.markdown-body h2 {
  font-weight: var(--base-text-weight-semibold, 600);
  padding-bottom: .3em;
  font-size: 1.5em;
  border-bottom: 1px solid var(--color-border-muted)
}

.markdown-body h3 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 1.25em
}

.markdown-body h4 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: 1em
}

.markdown-body h5 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: .875em
}

.markdown-body h6 {
  font-weight: var(--base-text-weight-semibold, 600);
  font-size: .85em;
  color: var(--color-fg-muted)
}

.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px
}

.markdown-body blockquote {
  margin: 0;
  padding: 0 1em;
  color: var(--color-fg-muted);
  border-left: .25em solid var(--color-border-default)
}

.markdown-body ul,
.markdown-body ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em
}

.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman
}

.markdown-body ul ul ol,
.markdown-body ul ol ol,
.markdown-body ol ul ol,
.markdown-body ol ol ol {
  list-style-type: lower-alpha
}

.markdown-body dd {
  margin-left: 0
}

.markdown-body tt,
.markdown-body code,
.markdown-body samp {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px
}

.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
  word-wrap: normal
}

.markdown-body .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor
}

.markdown-body input::-webkit-outer-spin-button,
.markdown-body input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none
}

.markdown-body:before {
  display: table;
  content: ""
}

.markdown-body:after {
  display: table;
  clear: both;
  content: ""
}

.markdown-body>*:first-child {
  margin-top: 0 !important
}

.markdown-body>*:last-child {
  margin-bottom: 0 !important
}

.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none
}

.markdown-body .absent {
  color: var(--color-danger-fg)
}

.markdown-body .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1
}

.markdown-body .anchor:focus {
  outline: none
}

.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre,
.markdown-body details {
  margin-top: 0;
  margin-bottom: 16px
}

.markdown-body blockquote>:first-child {
  margin-top: 0
}

.markdown-body blockquote>:last-child {
  margin-bottom: 0
}

.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden
}

.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none
}

.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible
}

.markdown-body h1 tt,
.markdown-body h1 code,
.markdown-body h2 tt,
.markdown-body h2 code,
.markdown-body h3 tt,
.markdown-body h3 code,
.markdown-body h4 tt,
.markdown-body h4 code,
.markdown-body h5 tt,
.markdown-body h5 code,
.markdown-body h6 tt,
.markdown-body h6 code {
  padding: 0 .2em;
  font-size: inherit
}

.markdown-body summary h1,
.markdown-body summary h2,
.markdown-body summary h3,
.markdown-body summary h4,
.markdown-body summary h5,
.markdown-body summary h6 {
  display: inline-block
}

.markdown-body summary h1 .anchor,
.markdown-body summary h2 .anchor,
.markdown-body summary h3 .anchor,
.markdown-body summary h4 .anchor,
.markdown-body summary h5 .anchor,
.markdown-body summary h6 .anchor {
  margin-left: -40px
}

.markdown-body summary h1,
.markdown-body summary h2 {
  padding-bottom: 0;
  border-bottom: 0
}

.markdown-body ul.no-list,
.markdown-body ol.no-list {
  padding: 0;
  list-style-type: none
}

.markdown-body ol[type=a] {
  list-style-type: lower-alpha
}

.markdown-body ol[type=A] {
  list-style-type: upper-alpha
}

.markdown-body ol[type=i] {
  list-style-type: lower-roman
}

.markdown-body ol[type=I] {
  list-style-type: upper-roman
}

.markdown-body ol[type="1"] {
  list-style-type: decimal
}

.markdown-body div>ol:not([type]) {
  list-style-type: decimal
}

.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
  margin-top: 0;
  margin-bottom: 0
}

.markdown-body li>p {
  margin-top: 16px
}

.markdown-body li+li {
  margin-top: .25em
}

.markdown-body dl {
  padding: 0
}

.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: var(--base-text-weight-semibold, 600)
}

.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px
}

.markdown-body table th {
  font-weight: var(--base-text-weight-semibold, 600)
}

.markdown-body table th,
.markdown-body table td {
  padding: 6px 13px;
  border: 1px solid var(--color-border-default)
}

.markdown-body table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted)
}

.markdown-body table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle)
}

.markdown-body table img {
  background-color: transparent
}

.markdown-body img[align=right] {
  padding-left: 20px
}

.markdown-body img[align=left] {
  padding-right: 20px
}

.markdown-body .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent
}

.markdown-body span.frame {
  display: block;
  overflow: hidden
}

.markdown-body span.frame>span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid var(--color-border-default)
}

.markdown-body span.frame span img {
  display: block;
  float: left
}

.markdown-body span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: var(--color-fg-default)
}

.markdown-body span.align-center {
  display: block;
  overflow: hidden;
  clear: both
}

.markdown-body span.align-center>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center
}

.markdown-body span.align-center span img {
  margin: 0 auto;
  text-align: center
}

.markdown-body span.align-right {
  display: block;
  overflow: hidden;
  clear: both
}

.markdown-body span.align-right>span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right
}

.markdown-body span.align-right span img {
  margin: 0;
  text-align: right
}

.markdown-body span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden
}

.markdown-body span.float-left span {
  margin: 13px 0 0
}

.markdown-body span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden
}

.markdown-body span.float-right>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right
}

.markdown-body code,
.markdown-body tt {
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: var(--color-neutral-muted);
  border-radius: 6px
}

.markdown-body code br,
.markdown-body tt br {
  display: none
}

.markdown-body del code {
  text-decoration: inherit
}

.markdown-body samp {
  font-size: 85%
}

.markdown-body pre code {
  font-size: 100%
}

.markdown-body pre>code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0
}

.markdown-body .highlight {
  margin-bottom: 16px
}

.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal
}

.markdown-body .highlight pre,
.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: var(--color-canvas-subtle);
  border-radius: 6px
}

.markdown-body pre code,
.markdown-body pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0
}

.markdown-body .csv-data td,
.markdown-body .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap
}

.markdown-body .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: var(--color-canvas-default);
  border: 0
}

.markdown-body .csv-data tr {
  border-top: 0
}

.markdown-body .csv-data th {
  font-weight: var(--base-text-weight-semibold, 600);
  background: var(--color-canvas-subtle);
  border-top: 0
}

.markdown-body [data-footnote-ref]:before {
  content: "["
}

.markdown-body [data-footnote-ref]:after {
  content: "]"
}

.markdown-body .footnotes {
  font-size: 12px;
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default)
}

.markdown-body .footnotes ol {
  padding-left: 16px
}

.markdown-body .footnotes ol ul {
  display: inline-block;
  padding-left: 16px;
  margin-top: 16px
}

.markdown-body .footnotes li {
  position: relative
}

.markdown-body .footnotes li:target:before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: "";
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px
}

.markdown-body .footnotes li:target {
  color: var(--color-fg-default)
}

.markdown-body .footnotes .data-footnote-backref g-emoji {
  font-family: monospace
}

.markdown-body .pl-c {
  color: var(--color-prettylights-syntax-comment)
}

.markdown-body .pl-c1,
.markdown-body .pl-s .pl-v {
  color: var(--color-prettylights-syntax-constant)
}

.markdown-body .pl-e,
.markdown-body .pl-en {
  color: var(--color-prettylights-syntax-entity)
}

.markdown-body .pl-smi,
.markdown-body .pl-s .pl-s1 {
  color: var(--color-prettylights-syntax-storage-modifier-import)
}

.markdown-body .pl-ent {
  color: var(--color-prettylights-syntax-entity-tag)
}

.markdown-body .pl-k {
  color: var(--color-prettylights-syntax-keyword)
}

.markdown-body .pl-s,
.markdown-body .pl-pds,
.markdown-body .pl-s .pl-pse .pl-s1,
.markdown-body .pl-sr,
.markdown-body .pl-sr .pl-cce,
.markdown-body .pl-sr .pl-sre,
.markdown-body .pl-sr .pl-sra {
  color: var(--color-prettylights-syntax-string)
}

.markdown-body .pl-v,
.markdown-body .pl-smw {
  color: var(--color-prettylights-syntax-variable)
}

.markdown-body .pl-bu {
  color: var(--color-prettylights-syntax-brackethighlighter-unmatched)
}

.markdown-body .pl-ii {
  color: var(--color-prettylights-syntax-invalid-illegal-text);
  background-color: var(--color-prettylights-syntax-invalid-illegal-bg)
}

.markdown-body .pl-c2 {
  color: var(--color-prettylights-syntax-carriage-return-text);
  background-color: var(--color-prettylights-syntax-carriage-return-bg)
}

.markdown-body .pl-sr .pl-cce {
  font-weight: 700;
  color: var(--color-prettylights-syntax-string-regexp)
}

.markdown-body .pl-ml {
  color: var(--color-prettylights-syntax-markup-list)
}

.markdown-body .pl-mh,
.markdown-body .pl-mh .pl-en,
.markdown-body .pl-ms {
  font-weight: 700;
  color: var(--color-prettylights-syntax-markup-heading)
}

.markdown-body .pl-mi {
  font-style: italic;
  color: var(--color-prettylights-syntax-markup-italic)
}

.markdown-body .pl-mb {
  font-weight: 700;
  color: var(--color-prettylights-syntax-markup-bold)
}

.markdown-body .pl-md {
  color: var(--color-prettylights-syntax-markup-deleted-text);
  background-color: var(--color-prettylights-syntax-markup-deleted-bg)
}

.markdown-body .pl-mi1 {
  color: var(--color-prettylights-syntax-markup-inserted-text);
  background-color: var(--color-prettylights-syntax-markup-inserted-bg)
}

.markdown-body .pl-mc {
  color: var(--color-prettylights-syntax-markup-changed-text);
  background-color: var(--color-prettylights-syntax-markup-changed-bg)
}

.markdown-body .pl-mi2 {
  color: var(--color-prettylights-syntax-markup-ignored-text);
  background-color: var(--color-prettylights-syntax-markup-ignored-bg)
}

.markdown-body .pl-mdr {
  font-weight: 700;
  color: var(--color-prettylights-syntax-meta-diff-range)
}

.markdown-body .pl-ba {
  color: var(--color-prettylights-syntax-brackethighlighter-angle)
}

.markdown-body .pl-sg {
  color: var(--color-prettylights-syntax-sublimelinter-gutter-mark)
}

.markdown-body .pl-corl {
  text-decoration: underline;
  color: var(--color-prettylights-syntax-constant-other-reference-link)
}

.markdown-body g-emoji {
  display: inline-block;
  min-width: 1ch;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol;
  font-size: 1em;
  font-style: normal !important;
  font-weight: var(--base-text-weight-normal, 400);
  line-height: 1;
  vertical-align: -.075em
}

.markdown-body g-emoji img {
  width: 1em;
  height: 1em
}

.markdown-body .task-list-item {
  list-style-type: none
}

.markdown-body .task-list-item label {
  font-weight: var(--base-text-weight-normal, 400)
}

.markdown-body .task-list-item.enabled label {
  cursor: pointer
}

.markdown-body .task-list-item+.task-list-item {
  margin-top: 4px
}

.markdown-body .task-list-item .handle {
  display: none
}

.markdown-body .task-list-item-checkbox {
  margin: 0 .2em .25em -1.4em;
  vertical-align: middle
}

.markdown-body .contains-task-list:dir(rtl) .task-list-item-checkbox {
  margin: 0 -1.6em .25em .2em
}

.markdown-body .contains-task-list {
  position: relative
}

.markdown-body .contains-task-list:hover .task-list-item-convert-container,
.markdown-body .contains-task-list:focus-within .task-list-item-convert-container {
  display: block;
  width: auto;
  height: 24px;
  overflow: visible;
  clip: auto
}

.markdown-body ::-webkit-calendar-picker-indicator {
  filter: invert(50%)
}

.markdown-custom-styles {
  color: inherit;
  background-color: transparent
}

.markdown-custom-styles>p {
  margin-bottom: 5px
}

.markdown-custom-styles>ul,
.markdown-custom-styles ol {
  list-style: disc;
  padding-left: 1em;
  margin-bottom: 5px
}

.markdown-custom-styles li p {
  margin-top: 5px;
  margin-bottom: 5px
}

.markdown-custom-styles pre {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px
}

.markdown-custom-styles pre code {
  white-space: pre-wrap;
  padding: 10px
}

.markdown-custom-styles img {
  max-width: min(80%, 300px);
  margin-top: 5px
}

.markdown-custom-styles a:not(:has(sup)) {
  color: inherit;
  text-decoration: underline
}

.xzypad{
  padding: 0 50px;
}
.py-3{
  padding-top: 16px;
  padding-bottom: 16px;
}
.top_box{
    height: 68px;
    box-shadow: 0px -1px 0px 0px #F4F4F4 inset;
    box-sizing: border-box;
    padding: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top_box .tit{
  font-family: Source Han Sans CN;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  padding: 0;
}
.content{
  display: flex;
}
.con_left{
  width: 40%;
  padding: 0 30px;
  border-right: 1px solid #F4F4F4;
}
.con_right{
  flex: 1;
  padding: 0 30px;
}
