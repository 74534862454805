.main{
    .cont{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}
.title{
    height: 50px;
    font-weight: 600;
    font-size: 36px;
    color: #31393E;
    line-height: 50px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-top: 58px;
    .dian{
        width: 8px;
        height: 8px;
        background: #31393E;
    }
    .num{
        color: #02C1A3;
    }
}
.subtitle{
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #31393E;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-top: 11px;
}
.search_box{
    width: 670px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    margin-top: 23px;
    .search_input{
        font-weight: 400;
        font-size: 16px;
        line-height: 50px;
        text-align: left;
        font-style: normal;
        background: #FFFFFF;
        input::placeholder {
            color: #C9C9C9;
        }
    }
    .search_box_img{
        width: 50px;
        height: 50px;
        background: #02C1A3;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09);
        border-radius: 0px 8px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon_search{
            width: 29px;
            height: 29px;
        }
    }
}
.tools-box{
    width: 670px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    img{
      width: 23px;
      height: 20px;
      margin-right: 10px;      
    }
    .ai-group{
        width: 108px;
        height: 32px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09);
        border-radius: 16px;
        font-weight: 400;
        font-size: 16px;
        color: #31393E;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
}
.models{
    width: 100%;
    display: grid;
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill,340px);
    margin-top: 40px;
    flex: 1;
    padding-bottom: 40px;
    overflow-y: scroll;
    .model{
        position: relative;
        width: 340px;
        height: 131px;
        background: #FFFFFF;
        border-radius: 8px;
        padding-right: 17px;
        display: flex;
        margin-right: 20px;
        // margin-bottom: 20px;
        cursor: pointer;
        &:hover{
            box-shadow: 0 16px 24px -16px rgb(0 0 0 / 50%);
            transform: scale(1.05);
            z-index: 10;
        }
        .mod-tag{
            width: 64px;
            height: 18px;
            background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
            border-radius: 0px 8px 0px 8px;
            font-weight: 500;
            font-size: 11px;
            color: #FFFFFF;
            line-height: 18px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
        }
        .mod-logo{
            width: 40px;
            height: 40px;
            margin: 25px 20px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .mod-info{
            flex: 1;
            .mod-name{
                font-weight: 600;
                font-size: 16px;
                color: #31393E;
                line-height: 22px;
                margin-top: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .mod-introduce{
                height: 34px;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
                text-align: left;
                font-style: normal;
                margin-top: 7px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .mod-type{
                width: 48px;
                height: 14px;
                background: #EBF4FF;
                border-radius: 0px 8px 0px 8px;
                font-weight: 500;
                font-size: 10px;
                color: #107FFC;
                line-height: 14px;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}

.modelbg{
    background-image: url('../../../assets/v2/imgs/bg2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.nodata{
    padding-top: 46px;
    img{
        width: 276px;
        height: 184px;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        color: #9A9A9A;
        line-height: 20px;
        text-align: center;
    }
}