/* ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
} */
/* a {
  text-decoration: none;
} */

/* .body{
  margin: 0 auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
} */

.ul{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.item{
  width: 344px;
  height: 254px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: .2s;
  transform: scale(1);
  word-break: break-all;
  background: #F8F8F8;
}
.item_select{
  border: 1px solid #4D4D4D;
}
.item:hover{
  box-shadow: 0 16px 24px -16px rgb(0 0 0 / 50%);
  transform: scale(1.05);
  z-index: 10;
}
.item .icon{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.item .icon img{
  width: 40px;
  height: 40px;
}
.item .name{
  width: 70%;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
}
.item .desc{
  width: 100%;
  font-size: 14px;
  text-align: center;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;	
  color: #6c7a77;
}
.ellipsis{
	overflow: hidden;			
	text-overflow: ellipsis;	
}
.btn{
  font-size: 14px;
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 5px;
  background-color: #f3f6f8;
  border: 1px solid #dbe3e7;
  cursor: pointer;
  margin-top: 10px;
}
.btn:hover{
  background-color: #dbe3e7;
}
.flex-bet{
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.price{
  padding: 10px 0;
}
.code-pay{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tips-pay{
  font-size: 20px;
}


.buy_free_vip{
  width: 100%;
  height: 150px;
  background: linear-gradient(90deg, #C9E1FB 0%, #B7D7FF 30.21%, #D1E4FE 65.62%, #EDF3FD 100%);
  border-radius: 20px;
  overflow: hidden;
  padding: 30px 60px;
}
.buy_free_vip .imgbg{
  background-image: url(../../assets/free_vip_bg.png);
  background-size: 100% 100%;
}
.buy_free_vip .vip_tit{
  font-family: Source Han Sans CN;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #1E5AD2;
}

.buy_free_vip .vip_tips{
  font-family: Source Han Sans CN;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #1C7EFF;
  margin-top: 20px;
}
.price_num{
  color:#F51818;
}
.ask_num{
  color: #1C7EFF;
}
.pay_box{
  width: 100%;
  height: 276px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background-color: #f8f8f8;
}
.pay_qrcode_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 100px;
}
.pay_qrcode img{
  width: 124px;
  height: 124px;
}
.pay_qrcode{
  width: 140px;
  height: 140px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.wxpay{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.wxpay img{
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-top: 4px;
}
.tips{
  text-align: left;
}
.buy_content{
  padding: 30px;
}
.tipscontent{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}
.tipscontent img{
  margin-right: 5px;
}