.login-info{
  flex: 1;
  height: 179px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right{
  height: 100%;
  width: 100%;
  padding: 0 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login-img {
  width: 50px;
  height: 50px;
}

.txt1 {
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bolder;
  line-height: 18px;
  margin-top: 20px;
}
.login-btn{
  width: 266px;
  height: 44px;
  background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  letter-spacing: 2px;
  text-align: center;
}
.arco-checkbox-text{
  color:#333333;
}
.color-txt{
  color: rgba(9, 125, 255, 1);
}
.huoqu{
  padding: 10px;
}
.newcenter{
  top: 50%;
  transform: translateY(-50%);
}
.arco-input-group-addafter{
  border: none;
}
.arco-btn-outline:not(.arco-btn-disabled){
  border: none;
  // color: rgba(34, 34, 34, 1);
  // background-color: #fff;
}
.arco-input-inner-wrapper{
  background-color: #fff;
}
.arco-input-group-addbefore, .arco-input-group-addafter{
  background-color: #fff;
}
.arco-input-inner-wrapper:focus,.arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
  border-color: #fff;
  box-shadow: #fff;
}

.border{
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}
.download-btn{
  width: 400px;
  height: 50px;
  border-radius: 10px;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  margin-top: 20px;
  }
  .qrcodelogin{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .arco-input-group-wrapper.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-prefix > svg, .arco-input-group-wrapper.arco-input-custom-height .arco-input-inner-wrapper .arco-input-group-suffix > svg{
    font-size: 20px;
  }
  .arco-input-inner-wrapper.arco-input-inner-wrapper-large{
    padding: 0 12px;
  }
  .phoneIcon{
    width: 20px;
    height: 20px;
  }
  .wxchat{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wxchat .tit{
    font-family: Source Han Sans CN;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(34, 34, 34, 1);
  }
  .wxchat .qrcode-box{
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qrcode{
    width: 100%;
    height: 100%;
    background-color: black;
  }
  .tips{
    font-family: Source Han Sans CN;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 9px;
    color: rgba(153, 153, 153, 1);
  }
  .btn-box{
    width: 180px;
    height: 44px;
    border-radius: 10px;
    margin-top: 36px;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
    text-align: center;
    background: rgba(244, 244, 244, 1);
    color: rgba(77, 77, 77, 1);
    cursor: pointer;
  }

  .arco-checkbox-checked .arco-checkbox-mask{
    background-color: rgba(2, 193, 163, 1);
  }

  .arco-btn-outline.arco-btn-disabled {
    background-color: transparent;
    color: rgba(34, 34, 34, 1);
    border: 1px solid rgba(34, 34, 34, 1);
    cursor: not-allowed;
  }
  .arco-btn-outline:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    color:black;
  }

  .fixed{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .closed{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 14px;
    right: 25px;
    cursor: pointer;
  }
  .xieyi{
    padding: 20px 34px 30px 34px;
  }


  .buttn{
    width: 50px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    border-radius: 4px;
    border: 1px solid #148a81;
    text-align: center;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .cropper-box-wrap{
    display: flex;
    .cropper-box{
        width: 180px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .img{
      width: 70px;
      height: 70px;
      margin-left: 32px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }

.up-title{
  font-weight: 600;
  font-size: 20px;
  color: #31393E;
  line-height: 28px;
  text-align: center;
  margin-bottom: 31px;
}
.cropper-action{
  margin-top: 10px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .again-upload{
    font-weight: 600;
    font-size: 12px;
    color: #02C1A3;
    line-height: 22px;
    white-space: nowrap;
  }
  .cropper-action-icon{
    display: flex;
    justify-content: flex-end;
    gap: 0 15px;
  }
  .cropper-img{
    width: 180px;
    .cropper-container{
      width: 180px!important;
    }
  }
}
.arco-upload-list{
  display: none;
}
.upload-loading{
    width: 100%;
    height: 100%;
    display: flex;
    color: #02C1A3;
    align-items: center;
    justify-content: center;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
}

.btn_box_up{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .cancel_btn{
      width: 100px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 5px;
      border:2px solid #02C1A3;
      font-weight: bold;
      font-size: 13px;
      color: #02C1A3;
      cursor: pointer;
      margin-right: 20px;
  }
  .sure_btn{
      width: 100px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: linear-gradient( 95deg, #00AEFF 0%, #00DAAC 100%);
      border-radius: 5px;
      font-weight: bold;
      font-size: 13px;
      color: #ffffff;
      cursor: pointer;
  }
}